<template>
  <div class="bigbox">
    <div class="imgdiv" @click="goanswer(1)" v-if="isInterest == 0">
      <img src="@/assets/third/测评1.png" alt="" />
    </div>
    <div class="imgdiv" @click="goanswer(2)" v-if="isCharacter == 0">
      <img src="@/assets/third/测评2.png" alt="" />
    </div>
    <div class="imgdiv" @click="goanswer(3)" v-if="isAbility == 0">
      <img src="@/assets/third/测评3.png" alt="" />
    </div>
  </div>
</template>

<script>
import {
  getAnswerResult,
} from "@/api/comprehensive";

export default {
  name: "evaluation",
  data() {
    return {
      isAbility: 0,
      isCharacter: 0,
      isInterest: 0,
      id: "",
    };
  },
  methods: {
    goanswer(val) {
      // 去答题
      this.$router.push({
        path: "/volunterservce/Comprehensive/answer",
        query: { assess: val,id:this.id },
      });
    },
    getdetail() {
      // 调测评详情判断是否答完
      getAnswerResult({ id: this.id }).then((result) => {
        if (result.code == 1) {
          let detail = result.data || {}
          this.isAbility = detail.isAbility;
          this.isCharacter = detail.isCharacter;
          this.isInterest = detail.isInterest;
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    if(this.id){
      this.getdetail();
    }
  },
};
</script>

<style lang="less" scoped>
.bigbox {
  width: 100%;
  .imgdiv {
    width: 500px;
    height: 100px;
    margin: 0 auto;
    padding: 35px 0px;
    img {
      width: 500px;
      height: 100px;
    }
  }
}
</style>
